<template>
  <div class="ui-label-button">
    <div class="ui-label-button-text" :class="{ 'use-sub': useSub }" @click="$emit('click', toggle = !toggle)">
      <div
        class="ui-label-button-title"
        :style="{
          color: titleColor,
          fontSize: titleSize,
          fontWeight: titleWeight,
        }"
      >
        {{title}}
      </div>
      <div class="ui-label-button-subtitle" v-if="useSub">{{subtitle}}</div>
    </div>

    <template v-if="rightIcon === 'next'">
      <div class="ui-label-button-right" @click="$emit('click', toggle)">
        <icon src="img/common/right-arrow-gray.png" width="2rem" height="6rem" :cover="false" />
      </div>
    </template>
    <template v-if="rightIcon === 'down'">
      <div class="ui-label-button-right" @click="$emit('click', toggle)">
        <icon src="img/common/arrow-ico-down.svg" width="2.8rem" height="2.8rem" :cover="false" />
      </div>
    </template>
    <template v-if="rightIcon === 'toggle'">
      <div class="ui-label-button-right">
        <md-switch
          v-model="toggle"
          @change="value => $emit('click', value)"
        >
        </md-switch>
      </div>
    </template>
    <template v-else>
      <div class="ui-label-button-right" @click="$emit('click', toggle)">
        <slot />
      </div>
    </template>
  </div>
</template>

<script>
import Icon from '@/components/common/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    titleColor: {
      type: String,
      default: '#000',
    },
    titleSize: {
      type: String,
    },
    titleWeight: {
      type: Number,
    },
    subtitle: {
      type: String,
      default: null,
    },
    /**
     * toggle, next, down
     */
    rightIcon: {
      type: String,
      default: null,
    },
    toggleDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      toggle: false,
    }
  },
  computed: {
    useSub() {
      return Boolean(this.subtitle)
    },
  },
  created() {
    if (this.toggleDefault) this.toggle = true
  },
}
</script>

<style lang="scss">
@import '@/assets/css/constant.scss';

.ui-label-button {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ui-label-button-text {
    width: 100%;
    line-height: 6rem;
    .ui-label-button-title {
      color: #000;
      font-size: 1.9rem;
    }
    &.use-sub {
      margin-top: 1rem;
      line-height: 3rem;
    }
    .ui-label-button-subtitle {
      color: #a0a0a0;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }
  .ui-label-button-right {
    color: #000;
    font-size: 1.6rem;
    line-height: 6rem;
    .icon {
      margin-right: 2.4rem;
    }
  }

  &.active {
    .ui-label-button-right {
      .icon {
        transform: scaleY(1);
      }
    }
  }
}

.md-switch-container {
  background-color: #c6c5c5 !important;
  .md-switch-thumb {
    background-color: #f1f1f1 !important;
  }
}
.md-checked {
  .md-switch-container {
    background-color: #cfacda !important;
    .md-switch-thumb {
      background-color: #a059b6 !important;
    }
  }
}

.home-information {
  .ui-label-button-text {
    line-height: normal;
    .ui-label-button-title {
      font-size: 2.8rem;
    }
  }
  .ui-label-button-right {
    color: #000;
    font-size: 1.6rem;
    .icon {
      transition: all 0.3s;
      transform: scaleY(-1);
      margin-right: 0;
    }
  }
}
</style>
